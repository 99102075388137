/**
 * ESav Refund Store
 * ----------------------------
 *
 */
export const useRefundStore = defineStore('refund', () => {
    const refundOffers: Ref<RefundOffer[]> = ref([]);
    const refundCompletedOffers: Ref<RefundCompletedOffer[]> = ref([]);

    /**
     * ------------------------------------------------------------------------------------------
     */

    function reset() {
        refundOffers.value = [];
        refundCompletedOffers.value = [];
    }

    return {
        refundOffers,
        refundCompletedOffers,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
